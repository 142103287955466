import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentStatus',
})
export class PaymentStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'Paid':
        return 'paid';
      case 'Partially Paid':
        return 'partially_paid';
      case 'Not Paid':
        return 'rejected';
      default:
        return 'paid';
    }
  }
}
