import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { PanelMenuModule } from 'primeng/panelmenu';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { StepsModule } from 'primeng/steps';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CardModule } from 'primeng/card';
import { TagModule } from 'primeng/tag';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { MenuModule } from 'primeng/menu';
import { DividerModule } from 'primeng/divider';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessageService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TokenInterceptor } from '../interceptors/token-interceptor/token.interceptor';
import { HttpErrorInterceptor } from '../interceptors/http-error-interceptor/http-error.interceptor';
import { BreadCrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SpinnerService } from './services/spinner.service';
import { PaginationComponent } from './components/pagination/pagination.component';
import { CheckoutHelperService } from './services/checkout.helper.service';
import { FeatureFlagService } from './types/feature-flag.service.interface';
import { FeatureFlagServiceLD } from './feature-flag.service';
import { InfoComponent } from './components/info/info.component';
import { AccountActivateSuccessComponent } from './components/dialogs/account-activate-success/account-activate-success.component';
import { TagComponent } from './components/tag/tag.component';
import { TransactionListComponent } from './components/dialogs/transaction-list/transaction-list.component';
import { IssueCreditNoteComponent } from './components/dialogs/issue-credit-note/issue-credit-note.component';
import { AdditionalPaymentComponent } from './components/dialogs/additional-payment/additional-payment.component';
import { IssueCreditNoteSuccessComponent } from './components/dialogs/issue-credit-note-success/issue-credit-note-success.component';
import { PaymentStatusPipe } from './pipes/payment-status.pipe';
import { ZatcaStatusPipe } from './pipes/zatca-status.pipe';
import { InvoiceTypePipe } from './pipes/invoice-type.pipe';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    BreadCrumbComponent,
    PaginationComponent,
    SpinnerComponent,
    InfoComponent,
    AccountActivateSuccessComponent,
    TagComponent,
    TransactionListComponent,
    IssueCreditNoteComponent,
    AdditionalPaymentComponent,
    IssueCreditNoteSuccessComponent,
    PaymentStatusPipe,
    ZatcaStatusPipe,
    InvoiceTypePipe,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    ToastModule,
    RippleModule,
    InputTextModule,
    StepsModule,
    SelectButtonModule,
    PanelMenuModule,
    TableModule,
    BreadcrumbModule,
    ToastModule,
    CardModule,
    DialogModule,
    CalendarModule,
    RadioButtonModule,
    DropdownModule,
    ProgressSpinnerModule,
    TagModule,
    MenuModule,
    DividerModule,
    CheckboxModule,
    FormsModule,
    MultiSelectModule,
    HttpClientModule,
    TranslateModule,
    ReactiveFormsModule,
    PaginatorModule,
    ProgressBarModule,
    TooltipModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: FeatureFlagService,
      useClass: FeatureFlagServiceLD,
    },
    CheckoutHelperService,
    MessageService,
    SpinnerService,
  ],
  exports: [
    CommonModule,
    ButtonModule,
    ToastModule,
    RippleModule,
    CalendarModule,
    InputTextModule,
    PanelMenuModule,
    StepsModule,
    SelectButtonModule,
    TableModule,
    ToastModule,
    BreadcrumbModule,
    CardModule,
    DialogModule,
    RadioButtonModule,
    DropdownModule,
    ProgressSpinnerModule,
    TagModule,
    MenuModule,
    DividerModule,
    CheckboxModule,
    FormsModule,
    MultiSelectModule,
    AutoCompleteModule,
    SpinnerComponent,
    HttpClientModule,
    TranslateModule,
    BreadCrumbComponent,
    PaginationComponent,
    ProgressBarModule,
    InfoComponent,
    AccountActivateSuccessComponent,
    TagComponent,
    TransactionListComponent,
    IssueCreditNoteComponent,
    AdditionalPaymentComponent,
    IssueCreditNoteSuccessComponent,
    PaymentStatusPipe,
    ZatcaStatusPipe,
    InvoiceTypePipe,
  ],
})
export class SharedModule {}
