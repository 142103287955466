{
  "name": "rewaa-admin",
  "version": "0.35.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --open",
    "build": "ng build",
    "build-stage": "ng build --configuration=${stage}",
    "watch": "ng build --watch --configuration development",
    "lint": "eslint \"{src,test}/**/*.ts\" --fix",
    "lint-check": "sh ../../../tools/lint-check.sh rewaa-admin 90",
    "test:unit:cov:conservative": "jest --config ./jest.config.js --silent --forceExit --coverage --shard=${JEST_SHARD} --changedSince=${BASE_REF_JEST}",
    "test": "jest --silent",
    "test:ci": "jest --silent --forceExit",
    "test:watch": "jest --watch"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.3",
    "@angular/common": "^17.3.3",
    "@angular/compiler": "^17.3.3",
    "@angular/core": "^17.3.3",
    "@angular/forms": "^17.3.3",
    "@angular/platform-browser": "^17.3.3",
    "@angular/platform-browser-dynamic": "^17.3.3",
    "@angular/router": "^17.3.3",
    "@datadog/browser-rum": "^4.36.0",
    "@fullstory/browser": "^1.7.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@rewaa-team/types": "file:../../../packages/rewaa-types",
    "aws-amplify": "^6.0.16",
    "currency.js": "^2.0.4",
    "jsbarcode": "^3.11.6",
    "launchdarkly-js-client-sdk": "^3.1.4",
    "primeflex": "^3.3.1",
    "primeicons": "^6.0.1",
    "primeng": "^17.6.0",
    "qrious": "^4.0.2",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.4",
    "@angular-eslint/eslint-plugin": "^17.3.0",
    "@angular-eslint/eslint-plugin-template": "^17.3.0",
    "@angular-eslint/template-parser": "^17.3.0",
    "@angular/cli": "^17.3.4",
    "@angular/compiler-cli": "^17.3.4",
    "@types/jest": "^29.5.12",
    "@typescript-eslint/eslint-plugin": "^7.1.0",
    "@typescript-eslint/parser": "^7.1.0",
    "eslint": "^8.57.0",
    "eslint-config-airbnb-typescript": "^17.1.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-check-file": "^2.7.1",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-sonarjs": "^0.24.0",
    "eslint-plugin-storybook": "^0.8.0",
    "jest": "^29.7.0",
    "jest-junit": "^16.0.0",
    "jest-preset-angular": "^14.0.3",
    "prettier": "^3.2.5",
    "ts-jest": "^29.1.2",
    "typescript": "^5.3.3"
  }
}
